<template>
  <div class="transfer flex1 flexdc">
    <div class="flex1 df" style="justify-content: center;">
      <div class="list leftList">
        <div class="df title" style="align-items:flex-end">
          <h4>班级学员</h4>
          <span>（共{{studentList.length}}人）</span>
        </div>
        <div style="height:550px">
          <div class="ovy-a" style="padding:0">
            <div class="tableList">
              <el-table
                ref="multipleTable"
                :data="studentList"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column type="index" label="编号" align="center"></el-table-column>
                <el-table-column prop="userName" label="姓名" align="center"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="tool_box flexdcc">
        <div>
          <el-button size="small" class="tool_button" @click="fastGroup">快速分组</el-button>
        </div>
        <div class="button_group">
          <div class="ovy-a" style="padding:0">
            <div
              class="flexcc"
              v-for="(el,index) in studentGroup"
              :key="index"
              style="margin-bottom:10px"
            >
              <el-button size="small" @click="addToGroup(index)" class="tool_button">
                添加到第{{index+1}}组
                <i class="el-icon-right el-icon--right"></i>
              </el-button>
              <el-button
                size="small"
                @click="delGroup(index)"
                icon="el-icon-delete"
                style="margin-right:10px"
                class="bgc-bv"
              ></el-button>
            </div>
          </div>
        </div>
        <div class="flexdc">
          <el-button
            size="small"
            style="margin-bottom:10px"
            class="tool_button"
            @click="addGroup"
          >添加新组</el-button>
          <el-button size="small" class="tool_button" @click="resetGroup">分组重置</el-button>
        </div>
      </div>
      <div class="list rightList">
        <h4 class="title">分组学员（共{{studentGroup.length}}组）</h4>
        <div style="height:550px">
          <div class="ovy-a" style="padding:0">
            <div class="group">
              <div class="group_item flexdc" v-for="(el,index) in studentGroup" :key="index">
                <span>第{{index+1}}组</span>
                <el-table
                  ref="multipleTable"
                  :data="el.G_item"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="tableHeader"
                  @selection-change="handleSelectionChange"
                  :header-click="headerClick"
                >
                  <el-table-column type="selection" align="center" width="55"></el-table-column>
                  <el-table-column type="index" label="编号" align="center"></el-table-column>
                  <el-table-column prop="userName" label="姓名" align="center"></el-table-column>
                  <el-table-column label="x" align="center">
                    <template slot="header" slot-scope="scope">
                      <i class="el-icon-close" @click="renderHeader(scope,index)"></i>
                    </template>
                    <template slot-scope="scope">
                      <i class="el-icon-close" @click="delstudent(scope.row.userId,index)"></i>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="flexcc" style="padding:10px 0;">
                <el-button size="small" style="width:100px" @click="delstudent(index)">删除</el-button>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="快速分组" :before-close="close" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="groupForm" :rule="rule" style="width:100%">
        <el-form-item label="分组数：" label-width="80px" prop="groupNum">
          <el-input v-model="groupForm.groupNum"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="groupOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "transfer",
  data() {
    return {
      // stu: ""
      tableHeader: {
        background: "#f2f7fd",
        color: "#333",
        borderColor: "#f2f7fd",
        fontSize: "14px",
        fontWeight: 500
      },
      dialogFormVisible: false,
      groupForm: {
        groupNum: ""
      },
      rule: {
        groupNum: [{ message: "请输入分组数", trigger: "blur" }]
      },
      selection: [], // 左侧列表
      copyStudentList: [],
      userNum: 0,
      deleteGroupIds: [],
      studentList: [],
      studentGroup: [],
      once: true
    };
  },
  watch: {
    studentLists: function(val) {
      if (val.length) {
        this.studentLists.forEach(el => {
          this.studentList.push(Object.assign({}, el));
        });
      }
    },
    studentGroups: function(val) {
      if (this.once) {
        if (val.length > 0) {
          this.studentGroups.forEach(el => {
            // console.log(el);
            this.studentGroup.push(Object.assign({}, el));
            // console.log(this.studentGroup);
          });
        } else {
          this.studentGroup = [
            {
              G_item: []
            }
          ];
        }
        this.once = false;
      }
    }
  },
  created() {
    ////
    this.copyStudentList = this.studentGroups;
    this.studentGroups.forEach(item => {
      this.userNum += item.G_item.length;
    });
    if (this.studentLists.length) {
      this.userNum += this.studentLists.length;
    }
  },
  props: {
    studentLists: {
      type: Array,
      default: function() {
        return [];
      }
    },
    studentGroups: {
      type: Array,
      default: function() {
        return [
          {
            G_item: []
          }
        ];
      }
    }
  },
  methods: {
    renderHeader({ column, $index }, index) {
      // console.log(index);
      const that = this;
      // return h("div", null, [
      //   h("span", null, [
      //     h(
      //       "i",
      //       {
      //         class: "el-icon-close",
      //         on: {
      //           click: function() {
      that.selection.forEach(el => {
        that.studentList.push(el);
        that.studentGroup = that.studentGroup.map(item => {
          return {
            ...item,
            G_item: item.G_item.filter(it => it.userId != el.userId)
          };
        });
      });
      // }
      // }
      //       },
      //       ""
      //     )
      //   ])
      // ]);
    },
    // 添加分组
    addGroup() {
      this.studentGroup.push({ G_item: [] });
    },
    // 删除分组
    delGroup(index) {
      const arr = this.studentGroup[index].G_item;
      if (this.studentGroup[index].groupId) {
        this.deleteGroupIds.push(this.studentGroup[index].groupId);
      }
      if (this.studentGroup.length) {
        this.studentGroup.splice(index, 1);
        this.studentBack(arr);
        // if (this.studentGroup.length < 1) {
        //   this.studentGroup.push({
        //     G_item: []
        //   });
        // }
      }
    },
    // 删除分组退回学员
    studentBack(arr) {
      arr.forEach(item => {
        this.studentList.push(item);
      });
    },
    //
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    // 添加到指定分组
    addToGroup(index) {
      this.studentGroup[index].G_item = [
        ...this.studentGroup[index].G_item,
        ...this.selection
      ];
      this.selection.forEach(el => {
        this.studentList = this.studentList.filter(
          it => it.userId != el.userId
        );
      });
    },
    // 删除组里的人
    delstudent(id, index) {
      const it = this.studentGroup[index].G_item.find(el => el.userId == id);
      this.studentGroup[index].G_item = this.studentGroup[index].G_item.filter(
        el => el.userId != id
      );
      if (this.studentGroup[index].G_item.length == 0) {
        if (this.studentGroup.length > 1) {
        }
      }
      this.studentList.push(it);
      this.studentList.sort((a, b) => {
        return a.number - b.number;
      });
    },
    // 重置分组
    resetGroup() {
      const list = this.studentGroup;
      let newList = [];
      list.map(el => {
        if (el.groupId) {
          this.deleteGroupIds.push(el.groupId);
        }
        el.G_item.map(it => {
          newList.push(it);
        });
      });
      this.studentList = [...this.studentList, ...newList];
      this.studentGroup = [
        {
          G_item: []
        }
      ];
    },
    // 快速分组
    fastGroup() {
      this.dialogFormVisible = true;
    },
    // 快速分组确定
    groupOk() {
      // 检查数字
      if (this.groupForm.groupNum > this.studentList.length) {
        this.$message({
          type: "warning",
          message: "分组数不能超过未分组人数"
        });
        return;
      }
      if (this.groupForm.groupNum <= 0) {
        this.$message({
          type: "warning",
          message: "分组数不能为0"
        });
        return;
      }
      this.dialogFormVisible = false;
      this.doGroup();
    },
    doGroup() {
      let userNum = 0; // 人数
      this.studentGroup.forEach(item => {
        if (item.groupId) {
          this.deleteGroupIds.push(item.groupId);
        }
        userNum += item.G_item.length;
      });
      if (this.studentList.length) {
        userNum += this.studentList.length;
      }

      const groupNum = this.groupForm.groupNum; // 5
      let length = 0; // 每组放几个人
      length = Math.floor(userNum / groupNum);
      let ismore = false;
      if (userNum % groupNum == 0) {
        ismore = false;
      } else {
        ismore = true;
      }
      let newArr = [];
      this.studentGroup.forEach(item => {
        item.G_item.forEach(el => {
          newArr.push(el);
        });
      });

      this.studentList = newArr.concat(this.studentList);
      this.studentGroup = [];
      for (let i = 0; i < groupNum; i++) {
        let newlist = [];
        for (let j = 0; j < length; j++) {
          let random = Math.floor(Math.random() * this.studentList.length);
          newlist.push(this.studentList[random]);
          this.studentList.splice(random, 1);
        }
        this.studentGroup.push({
          G_item: newlist
        });
      }
      if (ismore) {
        for (let m = 0; m < this.studentList.length; m++) {
          this.studentGroup[m].G_item.push(this.studentList[m]);
        }
        this.studentList.splice(0, this.studentList.length);
      }
      this.groupForm.groupNum = "";
    },
    // 保存
    dosave(url, query) {
      let groupList = [];
      groupList = this.studentGroup.map((el, index) => {
        const userIds = [];
        el.G_item.map(it => {
          userIds.push({ userId: it.userId, groupUserId: it.groupUserId });
        });
        return {
          groupName: "第" + (index + 1) + "组",
          groupId: el.groupId || null,
          userIds: userIds
        };
      });
      // console.log(groupList);
      this.doAjax(groupList, url, query);
    },
    doAjax(groupList, url, query) {
      this.$post("/biz/roll/group/insert", {
        groupList,
        deleteGroupIds: this.deleteGroupIds,
        projectId: this.$route.query.projectId
      }).then(res => {
        if (res.status == 0) {
          this.$router.push({
            path: url,
            query: query
          });
        }
      });
    },
    // 取消
    docencal() {
      this.$router.back();
    },
    // 关闭
    close() {
      this.groupForm.groupNum = "";
      this.dialogFormVisible = false;
    }
  }
};
</script>

<style lang="less">
.transfer {
  .list {
    padding: 20px 10px;
    .title {
      margin-bottom: 18px;
    }
    .el-table__row {
      background-color: #f2f7fd;
    }
  }
  .leftList {
    width: 30%;
    .tableList {
      background-color: #f2f7fd;
      height: 100%;
      .el-table {
        background-color: #f2f7fd;
      }
    }
  }
  .button_group {
    overflow: hidden;
    max-height: 400px;
  }
  .tool_box {
    padding: 0 10px 0 30px;
    align-items: flex-start;
    > div {
      margin: 0 10px 30px;
    }
    .tool_button {
      width: 9.375rem;
      margin-right: 10px;
    }
    .el-button + .el-button {
      margin: 0;
    }
    .delete_icon {
      color: #ccc;
    }
    .delete_icon:hover {
      color: red;
    }
  }
  .rightList {
    width: 35%;
    .group {
      .group_item {
        margin-bottom: 15px;
        background-color: #f2f7fd;
        .el-table {
          background-color: #f2f7fd;
        }
        > span {
          text-align: center;
          padding: 5px;
          font-size: 1rem;
          font-weight: 600;
        }
      }
      > div.group_item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn_box {
    padding: 20px 0 0;
  }
  .el-icon-close {
    cursor: pointer;
  }
}
</style>