<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$toPath('/web/sjz/jg/classList')">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push({path:'/web/sjz/jg/RollCallSet',query:{projectId:projectId}})">点名设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员分组</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <transfer
              ref="transfer"
              :studentLists="studentList"
              :studentGroups="studentGroup"
            ></transfer>
            <div class="flexjc btn_box">
              <el-button class="bgc-bv" round @click="docencal">取消</el-button>
              <el-button class="bgc-bv" round @click="dosave">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import transfer from "@/components/transfer.vue";
export default {
  name: "sjz/jg/studentGroup",
  components: {
    transfer
  },
  data() {
    return {
      studentList: [], // 未分组的学员列表
      studentGroup: [
        {
          G_item: []
        }
      ], // 分的组
      projectId: this.$route.query.projectId,
    };
  },
  created() {
    this.getstudentList();
    this.getstudentGroupDetail();
  },
  computed: {},
  methods: {
    // 获取学员列表
    getstudentList() {
      this.$post("/biz/roll/group/queryNotGroupProjectUser", {
        projectId: this.projectId
      }).then(res => {
        if (res.status == 0) {
          let datas = res.data || [];
          this.studentList = datas;
        }
      });
    },
    // 获取分组的列表
    getstudentGroupDetail() {
      this.$post("/biz/roll/group/queryGroupDetail", {
        projectId: this.projectId
      }).then(res => {
        if (res.status == 0) {
          let datas = res.data || [];
          const list = datas.map(el => {
            return {
              groupId:el.groupId || null,
              G_item: el.groupUserBOS || []
            };
          });
          this.studentGroup = list;
        }
      });
    },
    // 确定
    dosave() {
      this.$refs.transfer.dosave("/web/sjz/jg/RollCallSet",{projectId: this.$route.query.projectId});
    },
    // 取消
    docencal() {
      this.$router.push({
        path:'/web/sjz/jg/RollCallSet',
        query:{
          projectId:this.projectId,
        }
      });
    }
  }
};
</script>
<style lang="less">
.btn_box {
  padding: 40px 0 0;
}
</style>
